import Shuffle from "../utils/Shuffle"

for (const container of document.querySelectorAll('.js-filter-widget')) {
  const filter: Record<string, string> = {}
  const wrap = container.querySelector<HTMLElement>('.js-filter-widget-items')
  if (!wrap) {
    continue
  }
  const items = Array.from(wrap.children) as HTMLElement[]
  const shuffle = new Shuffle(wrap)

  function update() {
    for (const btn of container.querySelectorAll<HTMLButtonElement>('button[data-filter-attribute]')) {
      const attribute = btn.dataset.filterAttribute!
      const value = btn.dataset.filterValue
      const active = (!value && !filter[attribute]) || (value === filter[attribute])
      btn.classList.toggle('btn-primary', active)
    }
    shuffle.setChildren(items.filter((el) => {
      for (const [attr, value] of Object.entries(filter)) {
        if (!(el.dataset[attr]?.split(',').includes(value))) {
          return false
        }
      }
      return true
    }))
  }

  function setFilter(attribute: string, value: string | undefined) {
    if (!value) {
      delete filter[attribute]
    } else {
      filter[attribute] = value
    }
    update()
  }

  container.addEventListener('click', (e) => {
    if (!(e.target instanceof HTMLElement)) {
      return
    }
    const filterBtn = e.target.closest<HTMLButtonElement>('button[data-filter-attribute]')
    if (!filterBtn) {
      return
    }
    setFilter(filterBtn.dataset.filterAttribute!, filterBtn.dataset.filterValue)
  })

  update()
}
