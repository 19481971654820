/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { setStyle } from "./setStyle";
/* import "./shuffle.css"; */

type ShuffleOptions = {
	elementsEqual: (a: HTMLElement, b: HTMLElement) => boolean;
};

type ElementPosition = {
	top: number;
	left: number;
	width: number;
	height: number;
};

const getPosition = (el: HTMLElement): ElementPosition => {
	const { marginLeft, marginTop } = getComputedStyle(el);
	const { width, height } = el.getBoundingClientRect();
	return {
		top: el.offsetTop - Number(marginTop.replace("px", "")),
		left: el.offsetLeft - Number(marginLeft.replace("px", "")),
		width,
		height,
	};
};

const repaint = (): Promise<void> =>
	new Promise((resolve) =>
		requestAnimationFrame(() => requestAnimationFrame(() => resolve()))
	);

const getPositions = (
	elements: Iterable<HTMLElement>
): WeakMap<HTMLElement, ElementPosition> => {
	const result = new WeakMap<HTMLElement, ElementPosition>();
	for (const element of elements) {
		result.set(element, getPosition(element));
	}
	return result;
};

class Shuffle {
	wrap: HTMLElement;
	options: ShuffleOptions;
	destroyed = new WeakSet<Element>();

	constructor(wrap: HTMLElement, options?: Partial<ShuffleOptions>) {
		this.wrap = wrap;
		this.wrap.classList.add("shuffle");
		this.options = {
			elementsEqual: (a, b) =>
				a === b ||
				(!!a.getAttribute("id") &&
					a.getAttribute("id") === b.getAttribute("id")),
			...options,
		};
		this.wrap.addEventListener("transitionend", this.onTransitionEnd);
		this.wrap.addEventListener(
			"webkitTransitionEnd" as "transitionend",
			this.onTransitionEnd
		);
	}

	onTransitionEnd = (e: TransitionEvent): void => {
		const target = e.target as HTMLElement;
		if (target === this.wrap) {
			this.wrap.style.height = "";
		}
		if (target.parentElement === this.wrap) {
			if (this.destroyed.has(target)) {
				this.destroyed.delete(target);
				this.wrap.removeChild(target);
			}
			setStyle(target, {
				width: "",
				height: "",
				transform: "",
				opacity: "",
			});
			target.classList.remove("animate");
		}
	};

	getChildren(): HTMLElement[] {
		return (Array.from(this.wrap.children) as HTMLElement[]).filter(
			(x) => !x.classList.contains("destroyed")
		);
	}

	async _replaceChildren(
		nextChildren: HTMLElement[],
		immediate?: boolean
	): Promise<void> {
		const scrollTop = window.pageYOffset;
		nextChildren.forEach((x) => {
			setStyle(x, {
				position: "",
				left: "",
				top: "",
			});
			this.destroyed.delete(x);
		});
		if (immediate) {
			for (const child of this.getChildren()) {
				this.wrap.removeChild(child);
			}
			for (const child of nextChildren) {
				this.wrap.appendChild(child);
			}
			window.scrollTo(0, scrollTop);
			return;
		}
		const oldChildren = this.getChildren();
		const transformChildren: [HTMLElement, HTMLElement][] = [];
		for (const newChild of nextChildren) {
			for (const oldChild of oldChildren) {
				if (this.options.elementsEqual(oldChild, newChild)) {
					transformChildren.push([oldChild, newChild]);
					break;
				}
			}
		}

		const removeChildren = new Set(oldChildren);
		const newChildren = new Set(nextChildren);
		for (const [oldChild, newChild] of transformChildren) {
			removeChildren.delete(oldChild);
			newChildren.delete(newChild);
		}

		this.wrap.style.height = "";
		const oldPositions = getPositions(oldChildren);
		const oldHeight = this.wrap.clientHeight;
		oldChildren.forEach((x) => this.wrap.removeChild(x));
		for (const el of nextChildren) {
			this.wrap.appendChild(el);
		}
		const newPositions = getPositions(nextChildren);

		const newHeight = this.wrap.clientHeight;
		if (oldHeight !== newHeight) {
			this.wrap.style.height = oldHeight + "px";
		}
		for (const el of removeChildren) {
			const { top, left, width, height } = oldPositions.get(el)!;
			setStyle(el, {
				position: "absolute",
				top: top + "px",
				left: left + "px",
				width: width + "px",
				height: height + "px",
				opacity: "1",
			});
			this.destroyed.add(el);
			this.wrap.prepend(el);
		}
		for (const el of newChildren) {
			setStyle(el, {
				transform: "scale(0)",
				opacity: "0",
			});
		}
		for (const [oldChild, newChild] of transformChildren) {
			const oldPos = oldPositions.get(oldChild)!;
			const newPos = newPositions.get(newChild)!;
			const tx = oldPos.left - newPos.left;
			const ty = oldPos.top - newPos.top;
			setStyle(newChild, {
				width: oldPos.width + "px",
				height: oldPos.height + "px",
				transform: `translate(${tx}px, ${ty}px)`,
			});
			newChild.classList.remove("destroyed");
		}
		window.scrollTo(0, scrollTop);

		await repaint();
		// await timeout(100)

		if (oldHeight !== newHeight) {
			this.wrap.style.height = newHeight + "px";
		}
		for (const el of removeChildren) {
			el.classList.add("animate");
			setStyle(el, {
				transform: "scale: 0",
				opacity: "0",
			});
		}
		for (const el of newChildren) {
			el.classList.add("animate");
			setStyle(el, {
				transform: "scale(1)",
				opacity: "1",
			});
		}
		for (const [, newChild] of transformChildren) {
			const newPos = newPositions.get(newChild)!;
			newChild.classList.add("animate");
			setStyle(newChild, {
				width: newPos.width + "px",
				height: newPos.height + "px",
				transform: "",
			});
		}
		window.scrollTo(0, scrollTop);
	}

	setChildren = (
		nextChildren: HTMLElement[],
		immediate?: boolean
	): Promise<void> => {
		return this._replaceChildren(nextChildren, immediate);
	};

	addChildren = (newChildren: HTMLElement[]): void => {
		const children = this.getChildren();
		const nextChildren = children
			.map((el) => {
				for (const child of newChildren) {
					if (this.options.elementsEqual(el, child)) {
						return child;
					}
				}
				return el;
			})
			.concat(
				newChildren.filter((child) => {
					for (const el of children) {
						if (this.options.elementsEqual(el, child)) {
							return false;
						}
					}
					return true;
				})
			);
		this._replaceChildren(nextChildren);
	};

	filterChildren = (filter: (el: HTMLElement) => boolean): void => {
		const nextChildren = this.getChildren().filter(filter);
		this._replaceChildren(nextChildren);
	};

	removeChildren = (oldChildren: HTMLElement[]): void => {
		const nextChildren = this.getChildren().filter((el) => {
			for (const child of oldChildren) {
				if (this.options.elementsEqual(el, child)) {
					return false;
				}
			}
			return true;
		});
		this._replaceChildren(nextChildren);
	};
}

export default Shuffle;
