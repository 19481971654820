import Lightbox from "bs5-lightbox";
import Choices from "choices.js";

//Lightbox.initialize;

const options = {
	keyboard: true
};

document.querySelectorAll(".lightbox-toggle").forEach((el) =>
	el.addEventListener("click", (e) => {
		e.preventDefault();
		const lightbox = new Lightbox(el as HTMLImageElement, options);
		lightbox.show();
	})
);


//Page animation
const scrollElements = document.querySelectorAll(".js-scroll");

const elementInView = (el: Element, dividend = 1) => {
	const elementTop = el.getBoundingClientRect().top;

	return (
		elementTop <=
		(window.innerHeight || document.documentElement.clientHeight) / dividend
	);
};

const elementOutofView = (el: Element) => {
	const elementTop = el.getBoundingClientRect().top;

	return (
		elementTop > (window.innerHeight || document.documentElement.clientHeight)
	);
};

const displayScrollElement = (element: Element) => {
	element.classList.add("scrolled");
};

const hideScrollElement = (element: Element) => {
	element.classList.remove("scrolled");
};

const handleScrollAnimation = () => {
	scrollElements.forEach((el) => {
		if (elementInView(el, 1.25)) {
			displayScrollElement(el);
		} else if (elementOutofView(el)) {
			hideScrollElement(el);
		}
	});
};

window.addEventListener("scroll", () => {
	handleScrollAnimation();
});

//Timeline
function toggleNextSibling(event: { preventDefault: () => void; currentTarget: { querySelector: (arg0: string) => any; parentElement: any; }; }) {
	event.preventDefault();
	[].forEach.call(
		document.querySelectorAll(".vr"),
		function (el: { style: {
			transform: any; opacity: number 
} }) {
			el.style.opacity = 0;
			el.style.transform= 'translate3d(0, -100%, 0)';
		}
	);
	[].forEach.call(
		document.querySelectorAll(".information"),
		function (el: {
			style: {
				opacity: number;
			};
		}) {
			el.style.opacity = 0.3;
		}
	);
	var verticalLine = event.currentTarget.querySelector(".vr");
	verticalLine.style.opacity = 1;
	verticalLine.style.transform = "translate3d(0, -50%, 0)";
	var verticalLine = event.currentTarget.querySelector(".information");
	verticalLine.style.opacity = 1;

	[].forEach.call(
		document.querySelectorAll(".logo"),
		function (el: { style: {
			transform: string; opacity: number 
} }) {
			el.style.opacity = 0;
			el.style.transform = "translate3d(0, -20%, 0)";
		}
	);
	var parentDiv = event.currentTarget.parentElement;
	var imageDiv = parentDiv.querySelector(".logo");
	imageDiv.style.opacity = 1;
	imageDiv.style.transform = "translate3d(0, 0, 0)";
}

Array.prototype.slice
	.call(document.querySelectorAll(".timeline-text"))
	.forEach(function (head) {
		head.addEventListener("click", toggleNextSibling);
});

window.addEventListener("resize", function () {
  if (window.innerWidth > 768) { 
		Array.prototype.slice
			.call(document.querySelectorAll(".timeline-text"))
			.forEach(function (head) {
				head.addEventListener("click", toggleNextSibling);
		});
	}
})

//Form Dropdown
let selects = document.getElementsByTagName('select');
for (let select of selects) {
    new Choices(select, {
        searchEnabled: false,
        itemSelectText: 'auswählen',
        maxItemCount: 1,
        placeholder: true,
        placeholderValue: null
    })
}