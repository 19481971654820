import Swiper, { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Mousewheel} from "swiper";

//Stage slider

new Swiper(".stageSlider", {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	effect: "fade",
	// If we need pagination
	pagination: {
		el: ".stage-swiper-pagination",
		clickable: true,
		type: "bullets",
	},
	autoplay: {
		delay: 2500,
		disableOnInteraction: false,
	},
});

//Home slider

new Swiper(".homeSlider", {
	modules: [Navigation, Autoplay, EffectFade],
	effect: "fade",
	grabCursor: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	autoplay: {
		delay: 6000,
		disableOnInteraction: false,
	},
});

//Vertical slider
new Swiper(".verticalSlider", {
	modules: [Pagination, Autoplay, Keyboard, Mousewheel, EffectFade],
	// If we need pagination
	direction: "vertical",
	effect: "fade",
	spaceBetween: 30,
	threshold: 400,
	keyboard: {
		enabled: true,
	},
	mousewheel: {
		releaseOnEdges: true,
	},
	touchReleaseOnEdges: true,
	pagination: {
		el: ".vertical-swiper-pagination",
		clickable: true,
		type: "bullets",
	},
	autoplay: true,
});

//Image slider
new Swiper(".imageSlider", {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	grabCursor: true,
	pagination: {
		el: ".image-swiper-pagination",
		clickable: true,
		type: "bullets",
	},
	autoplay: false,
	breakpoints: {
		640: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 60,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 85,
		},
	},
});

//Image gallery slider

new Swiper(".imageGallerySlider", {
	modules: [Navigation, Autoplay, EffectFade],
	effect: "fade",
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	autoplay: false,
});

//News slider
new Swiper(".news-further-images-slider", {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	grabCursor: true,
	pagination: {
		el: ".news-swiper-pagination",
		clickable: true,
		type: "bullets",
	},
	autoplay: false,
	breakpoints: {
		640: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 60,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 85,
		},
	},
});