import 'parsleyjs/dist/i18n/de'

const locales = ['de']

const documentLocale = (document.documentElement.getAttribute('lang') || '').toLowerCase()
window.Parsley.setLocale(locales.includes(documentLocale) ? documentLocale : locales[0])

const parsleyConfig = {
  errorsContainer: function (pEle: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $element: { parent: () => { (): any; new (): any; siblings: { (arg0: string): any; new (): any } } }
  }) {
    const $err = pEle.$element.parent().siblings('.error-message')
    return $err
  },
  errorClass: 'is-invalid',
  errorsWrapper: '<div class="invalid-feedback"></div>',
  errorTemplate: '<span></span>',
  successClass: 'is-valid',
  trigger: 'blur change ',
  triggerAfterFailure: 'input change'
}

const formContact = document.querySelector(".form");
formContact?.parsley(parsleyConfig)
