for (const wrapper of document.querySelectorAll('.video-wrapper')) {
	const video = wrapper.querySelector('video')
	if (!video) {
		console.warn(".video-wrapper without video found.")
		continue
	}
	const circlePlayButton = wrapper.querySelector<HTMLButtonElement>('button[data-action="play"]')

	//Play Video
	video.controls = !circlePlayButton;
	function playVideo() {
		if (video?.play || video?.paused) {
			console.log('play')
			video.play()
			circlePlayButton && (circlePlayButton.style.display = "none");
			video.controls = true;
		}
	} 
	circlePlayButton?.addEventListener("click", playVideo);


	//End Video
	function endVideo(e: Event) {
		e.preventDefault()
		circlePlayButton && (circlePlayButton.style.display = "block")
		video!.controls = false;
	}

	video.addEventListener("ended", endVideo, false);
}