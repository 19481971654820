import $ from "jquery";
import lottie from "lottie-web";

$("[data-lottie-animation]").each(function () {
	lottie.loadAnimation({
		container: this,
		renderer: "svg",
		loop: "lottieLoop" in this.dataset,
		autoplay: "lottieAutoplay" in this.dataset,
		path: this.dataset.lottieAnimation,
	});
});
