var button = document.getElementById("menu-btn");
button?.addEventListener("click", function () {
	button?.classList.toggle("active");
	document.querySelector(".header-logo")?.classList.toggle("white-logo");
	document.querySelector(".header-text")?.classList.toggle("right-logo");
	document.querySelector(".slogan")?.classList.toggle("hide-slogan");
});

//Hide header logo for full screen pages
if (
	window.location.href ==
		window.location.protocol +
			"//" +
			window.location.host +
			"/de" +
			"/unternehmen/" ||
	window.location.href ==
		window.location.protocol +
			"//" +
			window.location.host +
			"/de" +
			"/hofleute/"
) {
	document.getElementById("header-text")!.style.display = "none";
	document.head.insertAdjacentHTML(
		"beforeend",
		`<style>main{padding-top:0}</style>`
	);
}

var elems = document.querySelector(".offcanvas");
onresize = function () {
	if (window.matchMedia("(max-width: 1500px)").matches) {
		elems?.classList.remove("offcanvas-start");
		elems?.classList.add("offcanvas-top");
	} else {
		elems?.classList.add("offcanvas-start");
		elems?.classList.remove("offcanvas-top");
	}
};

window.addEventListener("resize", onresize);

if (window.matchMedia("(max-width: 1500px)").matches) {
	elems?.classList.remove("offcanvas-start");
	elems?.classList.add("offcanvas-top");
}